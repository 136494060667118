import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarService } from '@app/core/services/sidebar.service'
import { Store } from '@ngrx/store';
import moment from 'moment'
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs'
import { Subject, takeUntil, tap } from 'rxjs';
import * as fromStore from '@app/shared/store'
import { selectAllUsers } from '@app/sidebar/store'
import { User } from '@app/shared'

type DASHBOARD_TYPES = 'logistic' | 'feedback'

@Component({
    selector: 'app-dashboard-tv',
    template: `
    @switch (type) {
      @case('feedback') {
        <app-dashboard-feedback
          [data]="data"
          [users]="users"
          [filters]="filters"
          (onUpdate)="updateFilter($event)"
        >
        </app-dashboard-feedback>
      }
      @case('logistic') {
        <app-dashboard-logistic
          [data]="data"
          [users]="users"
          [filters]="filters"
          (onUpdate)="updateFilter($event)"
        >
        </app-dashboard-logistic>
      }
      @default {
        <div class="w-full flex justify-center items-center mx-auto gap-5" style="height: 100svh;">
          <a [routerLink]="['/dashboards/tv']" [queryParams]="{ type: 'logistic' }" class="link-btn">Logistic</a>
          <a [routerLink]="['/dashboards/tv']" [queryParams]="{ type: 'feedback' }" class="link-btn">Feedback</a>
        </div>
      }
    }
  `,
    styles: `
    .link-btn{
      @apply py-2 px-4 bg-gray-200 text-black rounded-lg text-[22px];
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DashboardTvComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>()

  placements = NgxPopperjsPlacements;
  triggers = NgxPopperjsTriggers;

  data

  defaultDate = {
    startDate: moment.utc().startOf('month').toISOString(),
    endDate: moment.utc().endOf('month').toISOString(),
    // Analythic data range has to be client timezone, to be same as on feedback list
    analythicStartDate: moment().startOf('month').format(),
    analythicEndDate: moment().endOf('month').format(),
    dataRange: 'thisMonth'
  }

  sortable = {
    sort: 'incr',
    field: 'name'
  }
  averageRating
  ratingChartColors = {
    5: 'rgb(1, 68, 81)',
    3: 'rgb(6, 148, 162)',
    1: 'rgb(213, 245, 246)',
  }

  type: DASHBOARD_TYPES

  filters

  NgxPopperjsPlacements = NgxPopperjsPlacements
  NgxPopperjsTriggers = NgxPopperjsTriggers

  users: User[]


  constructor(
    private sidebarService: SidebarService,
    private store: Store<fromStore.State>,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe(params => {
        if(!params){
          return
        }

        let urlParams = structuredClone(params);
        if(urlParams.excludeIds?.length){
          urlParams.excludeIds = urlParams.excludeIds?.split(',')?.filter(id => id)
        }
        if(this.type !== urlParams.type){
          this.changeType(urlParams.type,urlParams)
        }

    })

    this.store.select(fromStore.selectDashboards).pipe(
      takeUntil(this.destroyed$),
    ).subscribe(
      (data) => {
        this.data = data
        if(this.data.logisticsPerformance?.length) {
          this.data.logisticsPerformance = this.data.logisticsPerformance.map((item, index) => {
            const pickupOnTime = (item?.stats?.origin?.onTime / item?.stats?.total * 100).toFixed()
            const deliveryOnTime = (item?.stats?.destination?.onTime / item?.stats?.total * 100).toFixed()
            item.performance = (parseFloat(deliveryOnTime) + parseFloat(pickupOnTime)) / 2;
            return item
          })?.sort((a, b) => b.stats?.total - a.stats?.total).sort((a, b) => b.performance - a.performance)
        }
        if(this.data.dispatcherTrips?.length) {
          this.data.dispatcherTrips.sort((a, b) => b.totalAmount - a.totalAmount)
        }
        this.cdr.detectChanges()
      }
    )


    this.store.select(selectAllUsers).pipe(
      takeUntil(this.destroyed$),
      tap(users => {
        this.users = users.filter(u=>u?.name)
      })
    ).subscribe()

    this.store.select(fromStore.selectAllFiltersDashboards)
      .pipe(
        takeUntil(this.destroyed$),
        tap(filter => {
          filter = {...filter, ...this.defaultDate}
          this.filters = filter
          if(filter?.type){
            this.store.dispatch(new fromStore.GetDashboardsTv(filter))
          }
          this.cdr.detectChanges()
        })
    )
    .subscribe()

    this.sidebarService.setShowNavigation(false)
    this.sidebarService.setShowSidebar(false)
  }

  changeType(type: DASHBOARD_TYPES, filters: {[key: string]: any}) {
    this.type = type
    if(type){
      this.store.dispatch(new fromStore.GetDashboardsFilters({ ...this.defaultDate, ...filters }))
    }
    this.cdr.detectChanges()
  }

  updateFilter(filters){
    const updatedFilters = {
      ...this.defaultDate,
      ...this.filters,
      ...filters
    }
    this.updateDashboard(updatedFilters)
  }

  updateDashboard(filters: {[key: string]: any}){
    let queryParams: {[key: string]: any} = {
      ...filters,
      type: this.type
    }
    this.store.dispatch(new fromStore.GetDashboardsFilters(queryParams))

    if(filters.excludeIds?.length){
      queryParams.excludeIds = queryParams?.excludeIds?.join(',')
    }

    this.router.navigate(
      ['dashboards/tv'],
      {
        queryParams: queryParams
      }
    )

  }

  ngOnDestroy() {
    this.sidebarService.setShowNavigation(true)
    this.sidebarService.setShowSidebar(true)
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
