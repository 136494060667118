import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Subject } from 'rxjs'
import { Router } from '@angular/router'
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs'
import Chart from 'chart.js/auto'
import { CurrencyPipe } from '@angular/common';
import { MyCurrencyPipe } from '@app/shared/pipes/my-currency.pipe'

@Component({
    selector: 'app-dashboard-company',
    templateUrl: './dashboard-company.component.html',
    styleUrls: ['./dashboard-company.component.scss'],
    providers: [CurrencyPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DashboardCompanyComponent implements OnInit, OnDestroy, AfterViewInit {

    public chart: any
    public chartLines: any
    public chartBar: any

    @ViewChild('barChart', { static: false })
    private barChartRef
    @Input()
    currentUser
    @Input()
    top
    topActive = 'dispatchers'
    sortable = {
        sort: 'incr',
        field: 'name'
    }
    @Input()
    shop
    @Input()
    fuelCost
    @Input()
    totalDriverPay
    @Input()
    set totalRevenue(val) {
        if (val && val?.totalRevenueDays) {
            if (this.chartLines) {
                this.chartLines.destroy()
            }

            let revenueLine = []
            if (val?.revenueLine) {
                revenueLine = this.transformNumbers(val?.revenueLine)
            }
            // console.log('totalRevenue ', val?.totalRevenue)
            // this.totalRevenueAmount = val?.totalRevenue
            this.totalRevenueAmount = this._MyCurrencyPipe.transform(val?.totalRevenue)
            this.createLinesChart(val?.totalRevenueDays, revenueLine)
        }
    }
    @Input()
    claimsCounts
    @Input()
    set setLoading(val) {
        if (val) {
            if (this.chart) {
                this.chart.destroy()
            }
        }

        this.loading = val
    }
    loading = false
    @Input()
    claimsAcceptedCounts
    @Input()
    claimsAcceptedAmount
    @Input()
    filters
    @Input()
    set profit(val) {
        if (val) {
            if (this.chart) {
                this.chart.destroy()
            }

            let local = val?.local
            let long = val?.long

            if (Number.isInteger(local)) {
                local = local;
            } else {
                local = Math.floor(local * 100);
            }

            if (Number.isInteger(long)) {
                long = long;
            } else {
                long = Math.floor(long * 100);
            }
            this.createChart(local, long)

            // console.log('local ', local)
            // console.log('long ', long)

            let localProfit = Number(local + long)
            // let localProfit = 1199969167.84
            // if (Number.isInteger(localProfit)) {
            //     localProfit = localProfit;
            // } else {
            //     localProfit = Math.floor(localProfit * 100);
            // }
            // console.log('localProfit ', localProfit)
            this._profit = this._MyCurrencyPipe.transform(localProfit)

            // console.log('_profit ', this._profit)

        }
    }
    _profit

    preloadItems = [1, 2, 3, 4, 5, 6]
    @Input()
    set monthlyExpense(val) {
        if (val && val?.labels) {
            if (this.chartBar) {
                this.chartBar.destroy()
            }
            // console.log('monthlyExpense ', val?.all)
            this.monthlyExpenseTotal = this._MyCurrencyPipe.transform(val?.all)

            let dataDriverPay = []
            if (val?.dataDriverPay) {
                dataDriverPay = this.transformNumbers(val?.dataDriverPay)
            }

            let dataClaim = []
            if (val?.dataClaim) {
                dataClaim = this.transformNumbers(val?.dataClaim)
            }

            let dataShop = []
            if (val?.dataShop) {
                dataShop = this.transformNumbers(val?.dataShop)
            }

            let fuelAmount = []
            if (val?.dataFuel) {
                fuelAmount = this.transformNumbers(val?.dataFuel)
            }

            if (this.barChartRef && this.barChartRef.nativeElement) {
                this.createChartBar(val?.labels, dataShop, dataDriverPay, dataClaim, fuelAmount)
            }
        }

    }
    monthlyExpenseTotal
    totalRevenueAmount

    NgxPopperjsTriggers = NgxPopperjsTriggers;
    NgxPopperjsPlacements = NgxPopperjsPlacements;

    destroyed$ = new Subject<boolean>();

    constructor(private _MyCurrencyPipe: MyCurrencyPipe, private _Router: Router) { }

    ngOnInit(): void {

    }

    transformNumbers(numbers) {
        return numbers.map(number => {
            let integerPart = Math.floor(number / 100)
            let decimalPart = number % 100
            let transformedNumber = integerPart + decimalPart / 100

            return transformedNumber
        })
    }

    transformNumbersSliceDollar(string) {
        if (string?.startsWith('$')) {
            string = string.slice(1)
        }
        if (string?.endsWith('.00')) {
            return string.slice(0, -3)
        }
        return string
    }

    onSort(type, field, sort) {

        this.sortable = {
            sort,
            field
        }
        if (field == 'name') {
            if (this.sortable.sort == 'incr') {
                this.top[type].sort((a, b) => a.name.localeCompare(b.name))
            } else {
                this.top[type].sort((a, b) => b.name.localeCompare(a.name))
            }
        } else {
            if (this.sortable.sort == 'incr') {
                this.top[type].sort((a, b) => a[field] - b[field])
            } else {
                this.top[type].sort((a, b) => b[field] - a[field])
            }
        }
    }


    ngAfterViewInit() {
        // this.createChart()
        if (this.chartLines) {
            this.chartLines.resize('100%', '100%')
        }

    }

    viewReportTotalRevenue() {
        this._Router.navigate(
            [`/reports`],
            {
                queryParams: {
                    report: 'truckTrips',
                    startDate: this.filters?.startDate,
                    endDate: this.filters?.endDate,
                    tripEndDateStart: this.filters?.startDate,
                    tripEndDateEnd: this.filters?.endDate
                }
            }
        )
    }

    viewReportTop() {
        let report = ''
        if (this.topActive == 'dispatchers') {
            report = 'dispatcherTrips'
        }
        if (this.topActive == 'customers') {
            report = 'byCustomer'
        }
        if (this.topActive == 'drivers') {
            report = 'driverTrips'
        }
        this._Router.navigate(
            [`/reports`],
            {
                queryParams: {
                    report,
                    startDate: this.filters?.startDate,
                    endDate: this.filters?.endDate,
                    tripEndDateStart: this.filters?.startDate,
                    tripEndDateEnd: this.filters?.endDate
                }
            }
        )
    }

    createChartBar(labels, dataShop, dataDriverPay, dataClaim, dataFuel) {
        let datasets = [
            {
                label: 'Shop',
                data: dataShop,
                backgroundColor: '#475565',
                borderRadius: 8,
            },
            {
                label: 'Driver Pay',
                data: dataDriverPay,
                backgroundColor: '#2669DC',
                borderRadius: 8,
            },
            {
                label: 'Claim',
                data: dataClaim,
                backgroundColor: '#76A9FA',
                borderRadius: 8,
            },
            {
                label: 'Fuel',
                data: dataFuel,
                backgroundColor: '#C3DDFD',
                borderRadius: 8,
            },
        ]

        this.chartBar = new Chart(this.barChartRef?.nativeElement, {
            type: "bar",
            data: {
                datasets,
                labels
            },
            options: {
                plugins: {
                    subtitle: {
                        display: false
                    },
                    legend: {
                        display: false
                    },
                    title: {
                        display: false,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                // scales: {
                //     x: {
                //         stacked: true,
                //     },
                //     y: {
                //         stacked: true,
                //         type: 'logarithmic',
                //         ticks: {
                //             callback: (value) => value.toLocaleString(),
                //         },
                //     }
                // },
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true
                    }
                }
            }
        })
    }

    createChart(localData, longData) {
        this.chart = new Chart("doughnut", {
            type: 'doughnut',
            data: {
                labels: [this._MyCurrencyPipe.transform(localData), this._MyCurrencyPipe.transform(longData)],
                datasets: [{
                    data: [localData, longData],
                    backgroundColor: [
                        '#278A3C',
                        '#2669DC',
                    ],
                    hoverOffset: 4
                }],
            },
            options: {
                aspectRatio: 1,
                responsive: true,
                maintainAspectRatio: false,
                cutout: '73%',
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            title: (tooltipItems) => tooltipItems[0].label,
                            label: (tooltipItems) => null,
                        },
                        padding: {
                            top: 7,
                            bottom: 0,
                            left: 8,
                            right: 8
                        }
                    }
                }
            }
        })
    }

    createLinesChart(total, line) {
        this.chartLines = new Chart("line", {
            type: 'line',
            data: {
                labels: total,
                datasets: [{
                    data: line,
                    borderColor: '#D02323',
                    backgroundColor: '#D02323',
                    tension: 0.4,
                    borderWidth: 2,
                    pointBackgroundColor: '#D02323',
                    pointBorderColor: '#D02323',
                    pointRadius: 5,
                    pointHoverRadius: 7,
                }],
            },
            options: {
                aspectRatio: 1.5,
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                },
            }
        })
    }

    addDotsToNumber(number) {
        return Math.floor(number).toLocaleString('en-US')
    }

    ngOnDestroy() {
        if (this.chartLines) {
            this.chartLines.destroy()
        }
        if (this.chart) {
            this.chart.destroy()
        }
        if (this.chartBar) {
            this.chartBar.destroy()
        }
        // this.destroyed$.next(true)
        // this.destroyed$.complete()
    }
}
